import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login', component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [

            {
               path: '/principal',
               name: 'Principal',
               meta: 'Principal',
               component: () => import(/* webpackChunkName: "about" */ '../views/Principal.vue')
            },
            // CADASTRO
            {
               path: '/cliente',
               name: 'Cliente',
               meta: 'Clientes',
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/cliente/Cliente.vue')
            },
            {
               path: '/clientes/cadastrar',
               name: 'ClienteCadastrar',
               meta: 'Clientes',
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/cliente/ClienteCadastro.vue')
            },
            {
               path: '/licenca',
               name: 'Licenca',
               meta: 'Licenças',
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/licenca/Licenca.vue')
            },
            {
               path: '/sistema',
               name: 'Sistema',
               meta: 'Softwares',
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/sistema/Sistema.vue')
            },
            {
               path: '/usuario',
               name: 'Usuário',
               meta: 'Usuários',
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/usuarios/Usuario.vue')
            },
            {
               path: '/auditoria',
               name: 'Auditoria',
               meta: 'Auditoria',
               component: () => import(/* webpackChunkName: "about" */ '../views/auditoria/Auditoria.vue')
            },
            {
               path: '/error_log',
               name: 'ErrorLog',
               meta: 'ErrorLog',
               component: () => import(/* webpackChunkName: "about" */ '../views/error_log/ErrorLog.vue')
            },
            {
               path: '/visualizacao_acesso',
               name: 'VisualizacaoAcesso',
               meta: 'Visual. de Acesso',
               component: () => import(/* webpackChunkName: "about" */ '../views/acessos/Acessos.vue')
            },
            //
          /**/
         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});

export default router
