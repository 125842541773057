/* eslint-disable */
import {Filtro} from '../models/filtro/filtro'

export const getLicencaSCH = (campo, valor, condicao) => {
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo, valor, condicao)
  filtro.fields = ['*']
  return filtro
}
export const LicencaSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields =['*']
  filtro.filters.addFilter('desativada', false, '=')
  return filtro
}
export const LicencaAtivaSCH = (perPage) => {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['dt_geracao',
    'dt_vencimento',
    'clientes.razao',
    'clientes.mensalista_id',
    'softwares.descricao',
    'tp_geracao',
    'licencas.cliente_id',
    'licencas.software_id',
    'clientes_softwares.bloqueio',
    'clientes_softwares.tp_licenca']
  filtro.addOrder('dt_geracao', 'asc')
  filtro.join.addLeft('clientes', 'clientes.cliente_id', 'clientes_softwares.cliente_id')
  filtro.join.addLeft('softwares', 'softwares.software_id', 'clientes_softwares.software_id')
  filtro.join.addLeft('licencas', 'licencas.software_id', 'clientes_softwares.software_id')
  filtro.filters.addFilter('clientes_softwares.bloqueio', true, '<>')
  return filtro
}
