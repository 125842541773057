<template>
			<div class="table_iterator" id="tabInter">
						<v-dialog v-model="loading" width="300">
									<v-card :color="setLoadingColor('green')" dark>
												<v-card-text>
															Carregando...
															<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
												</v-card-text>
									</v-card>
						</v-dialog>

						<v-progress-linear v-if="loadingProgress" indeterminate color="red" ></v-progress-linear>
						<v-table fixed-header sortable density="compact" >
									{{listaCabecalho}}
									<thead>
									<tr>
												<th :class="'v_tr text-'+item.align" v-for="(item, i) in lista_cab" role="columnheader" scope="col"
																:aria-sort="item.aria_sort" :id="'th_'+i" @click="ordenando(item,index)">
															<div :style="'padding-left: 0;padding-right: 0; justify-content:'  +item.align + '; cursor:' + (item.sortable ? 'pointer' : 'inherit')">
																		<span style="text-transform: uppercase; font-weight: bold">{{item.text}}</span>
																		<v-icon aria-label="ordenar"  v-if="isSortable(item)">{{item.icone}}</v-icon>
															</div>
												</th>
									</tr>
									</thead>
									<tbody class="linhas">
									<tr  v-for="item in itens" :key="item.name">
												<td v-for="(n, index) in cabecalho" :key="'index'+index" :class="'text-'+n.align" >
															<slot v-if="n.value !=='actions'" :name="n.slot" v-bind:item="item"></slot>
															<slot v-else name="acao" v-bind:item="item"></slot>
															<slot  name="expander" v-bind:item="item"></slot>
												</td>
									</tr>
									<tr v-if="itens.length <=0">
												<td colspan="100%">
															<v-alert color="deep-orange" icon="mdi-alert" title="Nada há exibir no momento" variant="outlined"></v-alert>
												</td>
									</tr>
									</tbody>
									<tfoot >
									<tr>
												<td colspan="100%">
															<v-container>
																		<div>
																					<v-row justify="space-between" style="align-items: flex-end;padding: 5px">
																								<v-col cols="12" sm="3" md="2" lg="2">
																											<v-select persistent-hint hint="itens por pagina" density="compact" v-model="select"
																																					:items="[10,20,50,100]"></v-select>
																								</v-col>
																								<v-col cols="12" sm="6" md="8" lg="8">
																											<v-pagination v-model="page" :length="totalPaginas" :total-visible="7" density="compact"
																																									rounded="circle" active-color="#06529A" variant="flat"/>
																								</v-col>
																								<v-col cols="12" sm="3" md="2" lg="2">
																											<v-btn v-if="showAddButton" @click="goToAdd" color="#06529A" :disabled="disabledAddButton"
																																		prepend-icon="mdi-plus" name="novo">
																														NOVO
																											</v-btn>
																								</v-col>
																								<slot name="slot_footer"></slot>
																					</v-row>

																		</div>
															</v-container>
												</td>
									</tr>
									</tfoot>
						</v-table>
			</div>

</template>

<script>
   /* eslint-disable */
   import {EventBus} from "../helpers/event-bus";
   import {mapGetters} from "vuex";
   import paginacao from '../helpers/paginacao'

   export default {
      mixins: [paginacao],
      name: "TableIterator",
      props: {
         cabecalho: {type: Array, default: () => []},
         itens: {type: Array, default: () => []},
         id: {default: 'tabela'},
         PorPage: {default: 10},
         totalPaginas: {default: 1},
         setPagina: {default: 1},
         showItemPerPag: {type: Boolean, default: true},
         dense: {type: Boolean, default: false},
         chave: {default: ''},
         hasheader: {type: Boolean, default: false},
         hideFooter: {type: Boolean, default: true},
         showAddButton: {type: Boolean, default: true},
         showContextMenu: {type: Boolean, default: false},
         disabledAddButton: {type: Boolean, default: false},
         pagSiple: {type: Boolean, default: false},
      },
      data: () => ({
         pageCount: 0,
         lista_cab: [],
         expanded: [],
         exp: '',
         loading: false,
         loadingProgress: true,
         singleExpand: false,
         select: 10,
         page: 1,
         sortBy: '',
         sortDesc: false,
         itensPerPage: 10,
      }),
      methods: {
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         isSortable(item) {
            if (item.hasOwnProperty('sortable')) {
               return item.sortable
            } else {
               return true
            }
         },
         setLoadingColor(cor) {
            return cor
         },
         setClasse(item) {
            var classe = 'text-left '

            if (this.validarCampo(item.align)) {
               classe = 'text-' + item.align + ' '
            }

            if (item.hasOwnProperty('sortable')) {
               classe += item.sortable ? 'sortable ' : ' '
            } else {
               classe += 'sortable '
            }
            return classe
         },
         ordenando(item, index) {
            if (this.isSortable(item)) {
               this.loading = true
               if (item.aria_sort === 'none') {
                  item.aria_sort = 'ascending'
                  item.icone = 'mdi-menu-up'

               } else if (item.aria_sort === 'ascending') {
                  item.aria_sort = 'descending'
                  item.icone = 'mdi-menu-down'
               } else if (item.aria_sort === 'descending') {
                  item.aria_sort = 'none'
                  item.icone = 'md-nada'
               }
            }
            this.lista_cab[index] = item

            this.ordenar(item.value, item.aria_sort)
         },

         goToAdd() {
            this.$emit('novo')
         },
         updateItemsPerPage(number) {
            this.itensPerPage = number
            this.$emit('por_paginas', parseInt(number))
         },
         ordenar(id, ordem) {

            var direcao = 'asc'

            if (!this.header) {
               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  var ordenar = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', ordenar)
               }
            } else {
               var orde = {
                  campo: '',
                  direction: ''
               }

               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  orde = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', orde)
               } else {
                  orde = {
                     campo: id,
                     direction: 'asc'
                  }
                  this.$emit('ordem', orde)
               }
            }
         },
      },
      computed: {
         listaCabecalho() {
            var cabecalho = JSON.parse(JSON.stringify(this.cabecalho))
            for (var i = 0; i < cabecalho.length; i++) {
               cabecalho[i].classe = this.setClasse(cabecalho[i])
               cabecalho[i].icone = 'mdi-chevron-nada'
               cabecalho[i].aria_sort = 'none'
            }
            this.lista_cab = cabecalho
         },

         getTotalPagina() {
            return Math.ceil(this.itens.length / this.select)
         },
         ...mapGetters('geral', {search: 'getSearch'}),
      },
      watch: {
         select: function () {
            this.select = parseInt(this.select) <= 0 ? 1 : this.select
            this.page = 1
            if (!this.pagSiple) {
               if (this.select > 0) {
                  this.updateItemsPerPage(parseInt(this.select))
               } else {
                  this.updateItemsPerPage(5)
               }
            }
         },
         setPagina: function () {
            if (this.setPagina) {
               this.page = 1
            }
         },
         page: function () {
            this.page = isNaN(this.page) ? 0 : this.page
            if (this.hideFooter){
               this.loading = true
            }
            if (!this.pagSiple) {
               this.$emit('paginas', parseInt(this.page))
            } else {
            }
         },
      },
      created() {
      },
      mounted() {
         var _this = this
         window.document.addEventListener('keydown', function fn(e) {
            e = e || window.event;
            const code = e.which || e.keyCode;
												if (code === 45) {
               _this.$emit('novo')
															e.preventDefault()
												}
         })

         EventBus.$on('SHOW_LISTA', (sucesso) => {
            this.loading = false
            this.loadingProgress = false
         })

      },
      beforeDestroy() {
         EventBus.$off('SHOW_LISTA')
      },

   }
</script>
<style lang="scss">
			$tabela: #82a8cc;
			.v_tr {
						/*color: #fff !important;*/
						background: rgb($tabela, .4) !important;
						/*font-weight: bold !important;*/
			}

			.linhas{
						tr {
									background-color: rgb($tabela, 0) !important;
									/*height: 30px!important;*/
						}

						tr:nth-child(even) {
									background-color: rgb($tabela, .25) !important;
						}

						tr:hover {
									background-color: rgb($tabela, .35) !important;
						}
			}


</style>


