/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {ErrorLogSCH} from "../../search/ErrorLogSCH"
import {Filtro} from "../../models/filtro/filtro";
import {getBaseSCH} from "../../search/BaseSCH";
import {LogAudit} from "../../models/log_audit";

const url = '/errorlogs';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  error_log: [],
  // logs_audit: new LogAudit()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaErrorLogs: (state) => {
    return state.all
  },
  pegarErrorLog: (state) => {
    return state.error_log
  },
};

const actions = {
  setErroLog({commit}, log ) {
    commit('SET_ERRORLOG', log)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['nome','descricao','acao'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getErrorLog({commit}, {id,onSucesso,onErro}) {

    try {
      let filtro = getBaseSCH('log_audit_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_ERRORLOG', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ErrorLogSCH(perPage))
  },

  async setAllErrorLogs ({ commit },{onSucesso,onErro}) {
    try {
       // console.log(state.filtro);
       const list = (await pegaDados(url+'/getview', state.filtro)).data;
      // console.log('SET_ERRORLOGS:', list);
      commit('SET_ERRORLOGS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async processarError({commit}, {item,acao, onSucesso, onErro}) {
    try {

      let res = await processar(url + '/commit', item, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      // this.dispatch('software/setAllSoftwares')
      onSucesso()
    } catch (error) {
      console.log('error: ', error)
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

  setErrorLog({commit}, error_log) {
    commit('SET_ERRORLOG', error_log)
  },

};

const mutations = {
  SET_LOG(state, log) {
    state.logs_audit = log
  },
  SET_ERRORLOGS (state, error_log) {
    state.all = error_log
  },
  SET_ERRORLOG (state, error_log) {
    state.error_log = error_log
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
