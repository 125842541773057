/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import usuario from './modules/usuarios'
// import configDash from "./modules/configDash";
import auditoria from "./modules/auditoria";
import licenca from "./modules/licenca";
import cliente from "./modules/cliente";
import software from "./modules/software";
import error_logs from "./modules/error_logs";
import acesso from "./modules/acesso";

export default createStore({
   modules: {
      geral,
      token,
      usuario,
      acesso,
      licenca,
      cliente,
      software,
      auditoria,
      error_logs
      // configDash,
   }
})
