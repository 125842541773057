/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getErrorLogSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}


export const ErrorLogSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['*']
  // filtro.fields = ['acao', 'dt_fitragem', 'dt_log','hr_log','nome']

  return filtro
}
