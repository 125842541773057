/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getClienteSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}
export const ClienteSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['razao','fantasia', 'contato','cnpj_cpf', 'desativado','bloqueado']
    filtro.addOrder('razao', 'asc')
    // filtro.join.addLeft('empresas','empresas.empresa_id','participantes.empresa_id')

  return filtro
}
