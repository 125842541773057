/* eslint-disable */
import {nao_autorizado, pegaDados} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {AcessoSCH, getAcessoSCH} from '../../search/AcessoSCH'
import {Filtro} from '../../models/filtro/filtro'

const url = 'acesso'

const state = {
  all: [],
  filtro: new Filtro(),
}

const getters = {
  listaAcesso: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
}
const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = []
    state.filtro.filters.addSearch(['fantasia', 'descricao','software_versao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getAcessoSCH('log_audit_id', id, '=')
    const list = (await pegaDados(url + '/get', filtro)).data

    EventBus.$emit('GET_LOG_CONCLUIDO', true, list.data[0])

  },
  setFiltro({commit}, perPage) {

    commit('SET_FILTRO', AcessoSCH(perPage))
  },
  async setAllAcesso({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data

      commit('SET_ALL', list)

      onSucesso()
    } catch (error) {
      nao_autorizado(error)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL(state, quadras) {
    state.all= quadras
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
