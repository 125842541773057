<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div id="modal_padrao">
						<v-dialog v-model="Mostrar"
																transition="dialog-bottom-transition"
																:width="width" :persistent="persistent"
																:fullscreen="fullscreen" :hide-overlay="overlay">
									<v-card id="drg" >
												<v-card-title class="headline popup-header" style="background: #e46f07; color: white">
															<span style="font-size: 1.5rem" > {{ getTitulo() }}</span>
															<slot name="close"></slot>

												</v-card-title>
												<v-card-text >
															<v-container fluid>
																		<slot></slot>
															</v-container>
												</v-card-text>
												<slot name="actions"></slot>
									</v-card>
						</v-dialog>
			</div>
</template>

<script>
   /* eslint-disable */

   export default {
      props: {
         titulo: {
            default: ''
         },
         modal: {
            default: false
         },
         persistent: {
            default: true
         },
         fullscreen: {
            default: false
         },
         dragable: {
            default: false
         },
         width: {
            default: '50%'
         }
      },
      name: "ModalCadastro",

      data() {
         return {
            overlay: false,
            classe: '',
            margem_left: '25',

         }
      },
      methods: {
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         getTitulo() {
            if (this.validarCampo(this.titulo)) {
               return this.titulo
            } else {
               return this.$router.currentRoute.meta
            }
         },
      },
      computed: {
         Mostrar: {
            get() {
               return this.modal
            },
            set() {
               return this.Mostrar
            }
         },
      },
      watch: {
         modal: function () {
            if (this.modal) {
               var _this = this
               var inter = setInterval(function () {
                  var mywidth = document.getElementById('drg')
                  if (mywidth !== null) {
                     var width = window.screen.width
                     var w = mywidth.clientWidth
                     var res = width - w
                     res = res / 2
                     res = (res * 100) / width
                     _this.margem_left = parseInt(res)
                  }
                  clearInterval(inter)
               }, 500)
            }
         }
      }
   }
</script>

<style lang="scss">

			.v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
						font-size: 16px !important;
			}


</style>
